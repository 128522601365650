/*
 * 업무구분: 안내자료
 * 화 면 명: MSPSA204M
 * 화면설명: 안내자료 검색
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.07
 * 작 성 자: 손평국
 */
<template>
  <ur-page-container title="안내자료 검색" :show-title="true" type="subpage">
    <ur-box-container direction="column" alignV="start" class="ns-sch-area">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-box">
        <div class="ns-date-picker multi">
          <mo-date-picker v-model="lv_StartDate" placeholder="자료등록 시작일" :min-date="minDate"/><span>~</span>
          <mo-date-picker v-model="lv_EndDate" placeholder="자료등록 종료일" :max-date="maxDate"/>
        </div>
        
        <div class="sch-box">
          <mo-text-field v-model="lv_NewSearchKeyword" underline placeholder="자료명을 입력하세요." @keyup.enter="fn_OnSearch"/>
        </div>
      </ur-box-container>
    </ur-box-container>
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnCancelBtnClicked">취소</mo-button>
        <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnSearch">조회</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPSA204M',
  // 현재 화면 ID
  screenId: 'MSPSA204M',
  // 컴포넌트 선언
  components: {
    
  },
  props: {
    
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      // 지점장 여부
      isMngr: false,
      // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
      authCd: 'U000',
      lv_StartDate: '',
      lv_EndDate: '',
      lv_NewSearchKeyword: '',
      maxDate: '',
      minDate: ''
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    
  },
  
  /** watch 정의 영역 */
  watch: {},



  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    this.fn_OnInit()
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    this.$BottomManager.fn_SetBottomVisible(true)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {

    /*********************************************************
    * Function명: fn_OnInit
    * 설명: 최초실행 
    * Params: N/A
    * Return: N/Ad
    *********************************************************/
    fn_OnInit () {
      let today = new Date()
      let todayStr = moment(today).format('YYYY-MM-DD')
      
      this.lv_EndDate = todayStr
      this.lv_StartDate = moment(today.setFullYear(today.getFullYear()-5)).format('YYYY-MM-DD')
      this.minDate = this.lv_StartDate
      this.maxDate = todayStr
      this.lv_NewSearchKeyword = ''
    },

    /*********************************************************
    * Function명: fn_OnCancelBtnClicked
    * 설명: 취소버튼 클릭 이벤트 핸들러
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OnCancelBtnClicked () {
      // 창 초기화
      //this.fn_OnInit()
      this.$router.push({name : 'MSPSA201M', params : {callerView : 'MSPSA204M'}})
    },

    /*********************************************************
    * Function명: fn_DpOpened
    * 설명: 달력 클릭 이벤트 
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_DpOpened () {
      // this.minDate = this.lv_StartDate
      // this.maxDate = this.lv_EndDate
    },


     
    fn_OnSearch () {
      if (this.lv_NewSearchKeyword.trim() === '') {
        this.$addSnackbar('검색할 자료명을 입력하세요..')
        return
      }
      //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
      this.$router.push(
          {
            name: 'MSPSA205M',
            params: {
              callerView: 'MSPSA204M',
              startDate: this.lv_StartDate,
              endDate: this.lv_EndDate,
              keyword: this.lv_NewSearchKeyword
            }
          }
        )
      // 창 초기화_데이터를 가져다 쓰기 위해 나중에 초기화
      this.fn_OnInit()
    }
  }
}
</script>
<style scoped>
</style>